import React, { useEffect, useState } from 'react'
import ContentDialogBox from '../../react_components/dialog_box/ContentDialogBox'
import NotificationMessages from '../../react_components/dialog_box/SuccessMessages'
import { Button, Card, Container, Tooltip } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download';
import DashboardIcon from '@mui/icons-material/Dashboard';

function SampleLinks({ vm }) {
    const [open, setOpen] = useState(false)
    async function loadSample(link) {
        if (link) {
            try {
                const response = await fetch(link)
                const result = await response.arrayBuffer()
                console.log("result", result);
                const filename = "sample-project";

                vm.loadProject(result)
                    .then(() => {
                        if (filename) {
                            // const uploadedProjectTitle = this.getProjectTitleFromFilename(filename);
                            console.log("filename", filename);
                        }
                    })
                    .catch(error => {
                        log.warn(error);
                        // this.props.onLoadingFinished("LOADING_VM_FILE_UPLOAD", loadingSuccess);
                        // alert(this.props.intl.formatMessage(messages.loadError)); // eslint-disable-line no-alert
                    })
                    .then(() => {
                        setOpen(false)
                        // console.log(this.props.loadingState, loadingSuccess);
                        // closeLoadingProject();
                        // toast.success("File Loaded Successfully")
                        // this.props.onLoadingFinished("LOADING_VM_FILE_UPLOAD", loadingSuccess);
                        // go back to step 7: whether project loading succeeded
                        // or failed, reset file objects
                        // this.removeFileObjects();
                    });
            } catch (error) {
                console.log(error);

            }
        }

    }
    return <>
        <Tooltip title="Sample Projects" placement="right">
            <Button onClick={() => {
                setOpen(true)
            }} size='small' style={{
                color: "white",
            }}>
                <DashboardIcon sx={{
                    fontSize: "30px",
                }} />
            </Button>
        </Tooltip>
        <ContentDialogBox
            DialogContainerStyle={{
                borderRadius: "10px",
                color: "white",
                fontweight: "bolder",
                textAlign: "center",
                color: "black",
            }}
            maxWidth='md'
            isOpen={open}
            onClose={() => { setOpen(false) }}
            content={<>
                <hr style={{ border: "1px solid white" }} />
                <h2>Samples</h2>
                <hr style={{ border: "1px solid white" }} />
                <Container style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                }}>
                    <Card style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "10px",
                        borderRadius: "10px",
                        margin: "10px",
                        maxWidth: "250px"
                    }} sx={{
                        ":hover": {
                            scale: "1.1"
                        }
                    }}>
                        <img src="https://open-editor-assets.s3.ap-south-1.amazonaws.com/assets/Make+Your+Own+Pet.png" style={{
                            maxWidth: "200px"
                        }} alt="" />
                        <div style={{
                            display: "flex",
                            width: "100%",
                        }}>
                            <Button variant='outlined' onClick={() => {
                                loadSample("https://open-editor-assets.s3.ap-south-1.amazonaws.com/assets/WeDo+1+-+Make+Your+Own+Pet.sb3")
                            }} style={{
                                margin: "10px",
                            }}>
                                Open
                            </Button>
                        </div>
                    </Card>
                    <Card style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "10px",
                        borderRadius: "10px",
                        margin: "10px",
                        maxWidth: "250px"
                    }} sx={{
                        ":hover": {
                            scale: "1.1"
                        }
                    }}>
                        <img src="https://open-editor-assets.s3.ap-south-1.amazonaws.com/assets/Duck+Hunt.png" style={{
                            maxWidth: "200px"
                        }} alt="" />
                        <div style={{
                            display: "flex",
                            width: "100%",
                        }}>
                            <Button variant='outlined' onClick={() => {
                                loadSample("https://open-editor-assets.s3.ap-south-1.amazonaws.com/assets/Duck+Hunt+WeDo+2.0.sb3")
                            }} style={{
                                margin: "10px",
                            }}>
                                Open
                            </Button>
                        </div>
                    </Card>
                    <Card style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "10px",
                        borderRadius: "10px",
                        margin: "10px",
                        maxWidth: "250px"
                    }} sx={{
                        ":hover": {
                            scale: "1.1"
                        }
                    }}>
                        <img src="https://open-editor-assets.s3.ap-south-1.amazonaws.com/assets/Move+the+Fox.png" style={{
                            maxWidth: "200px"
                        }} alt="" />
                        <div style={{
                            display: "flex",
                            width: "100%",
                        }}>
                            <Button variant='outlined' onClick={() => {
                                loadSample("https://open-editor-assets.s3.ap-south-1.amazonaws.com/assets/WeDo+2+-+Move+the+Fox.sb3")
                            }} style={{
                                margin: "10px",
                            }}>
                                Open
                            </Button>
                        </div>
                    </Card>
                    <Card style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "10px",
                        borderRadius: "10px",
                        margin: "10px",
                        maxWidth: "250px"
                    }} sx={{
                        ":hover": {
                            scale: "1.1"
                        }
                    }}>
                        <img src="https://open-editor-assets.s3.ap-south-1.amazonaws.com/assets/Inflating+Pufferfish.png" style={{
                            maxWidth: "200px"
                        }} alt="" />
                        <div style={{
                            display: "flex",
                            width: "100%",
                        }}>
                            <Button variant='outlined' onClick={() => {
                                loadSample("https://open-editor-assets.s3.ap-south-1.amazonaws.com/assets/WeDo+3+-+Inflating+Pufferfish.sb3")
                            }} style={{
                                margin: "10px",
                            }}>
                                Open
                            </Button>
                        </div>
                    </Card>
                    <Card style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "10px",
                        borderRadius: "10px",
                        margin: "10px",
                        maxWidth: "250px"
                    }} sx={{
                        ":hover": {
                            scale: "1.1"
                        }
                    }}>
                        <img src="https://open-editor-assets.s3.ap-south-1.amazonaws.com/assets/Blade+Ball+-+Hacked.sb3.png" style={{
                            maxWidth: "200px"
                        }} alt="" />
                        <div style={{
                            display: "flex",
                            width: "100%",
                        }}>
                            <Button variant='outlined' onClick={() => {
                                loadSample("https://open-editor-assets.s3.ap-south-1.amazonaws.com/assets/Roblox+Blade+Ball+-+Hacked.sb3")
                            }} style={{
                                margin: "10px",
                            }}>
                                Open
                            </Button>
                        </div>
                    </Card>












                </Container>
            </>}
        />

    </>
}

export default SampleLinks